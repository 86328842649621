import safe from "../assets/safeIcon.svg";
import reliable from "../assets/reliableIcon.svg";
import affordable from '../assets/afforIcon.svg'

export const features = [
  {
    title: "SAFETY",
    image: `${safe}`,
    description: `Offering passangers peace of mind with meticulously trained and
    experienced drivers,top-of-the-line vehicles equipped with advanced
    safety features, and rigorous adherence to strict safety protocols.`,
  },
  {
    title: "RELIABILITY",
    image: `${reliable}`,
    description: `Ensuring punctuality,seamless coordination, and consistence 
      highr-quality service,allowing clients to confidently rely on their 
      chauffeur for all their transportation needs.`,
  },
  {
    title: "AFFORDABLE",
    image:`${affordable}`,
    description: `With competitive pricing,offering value for money while
       maintaining the highest standards of comfort,elegance,and personalised service.`,
  },
];
